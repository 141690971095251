import React from 'react'
import './CaseStudies.css'
export const CaseStudies = () => {
  return (
    <div>
         <div className="casestudy">
            <h1 >UPCOMING CASE STUDIES</h1>
          
        </div>
    </div>
  )
}
