import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import Typewriter from "typewriter-effect";
import Slider from "./Components/Slider";
import Sliderwhy from "./Components/Sliderwhy";
import AnimatedDivs from "./Components/Animateddivs";
import TestimonialSlider from './Components/TestimonialSlider';
import Projects from "./Components/Projects";
import Mobile1 from "./assets/img/Mobile1.png";
import Laptop from "./assets/img/Laptop.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Landing() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {/* <!-- <section> --> */}
      <div className="mainContent">
        <div className="main-1">
          <div>
            <h2
              className="firstText"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              {" "}
              We are the best&nbsp;
            </h2>{" "}
          </div>

          <div
            className="secondText"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
                delay: 30,
                strings: [
                  "Graphic Designers",
                  "Ui/ux Developers",
                  "SAAS Development",
                  "Social Media Growth",
                  "Brand Research",
                ],
              }}
            />
          </div>
          <h5 data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            Welcome to STARTING CORE, the agency exclusively designed for
            founders and creators ready to elevate their digital presence! 🚀

          </h5>
          <form
            className="start"
            action=""
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <input
              className="form"
              type="search"
              placeholder="Enter your email"
              aria-label="Search"
            />
            <button className="btn_2" type="submit">
              <a style={{textDecoration:"none", color:"#F2F4F6"}} href="/Pricing">
                Start a project
              </a>
            </button>
          </form>

          {/* <img className="image" src="./images/Img1.png" alt="#" /> */}
        </div>

        <div
          className="main-2"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <img className="mobile" src={Mobile1} alt="#" />
          <img className="laptop" src={Laptop} alt="#" />
        </div>
      </div>

      {/* <!-- <section> --> */}
      <div id="Why">
        <div className="why_we">
          <div className="proje" data-aos="fade-up">
            <h1 className="numbers">25+</h1>
            <h3 className="number_description">
              Projects are successfully completed in 5 months.
            </h3>
          </div>
          <div className="proje" data-aos="fade-up">
            <h1 className="numbers">21</h1>
            <h3 className="number_description">
              Team members with various skill sets.
            </h3>
          </div>
          <div className="proje" data-aos="fade-up">
            <h1 className="numbers">100%</h1>
            <h3 className="number_description">
              We have achieved 100% client satisfaction.
            </h3>
          </div>
        </div>

        <div
          className="whyCaro"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
        >
          <Sliderwhy />
        </div>

        <div
          className="ourWork"
        >
          <h2 className="textOur font-face-sa" data-aos="fade-up"
            data-aos-anchor-placement="top-center">Our Work flow</h2>
          <h4 data-aos="fade-up"
            data-aos-anchor-placement="top-center">We solve clients' projects in a simple & efficient way</h4>

          <div className="flow" data-aos="fade-up"
            data-aos-anchor-placement="top-center">
            <div>
              <div>
                <div className="circle1">
                  <h1>1</h1>
                </div>
                <div className="circle circle-desc-one">
                  <h3>Research</h3>
                  <h4>We do research before we start any projects</h4>
                </div>
              </div>
              <svg className="vector1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 32" fill="none">
                <path strokeWidth="1" opacity="1" fill="white" d="M209.201 12.7316C209.454 12.6205 209.569 12.3255 209.458 12.0727L207.648 7.95279C207.537 7.69997 207.242 7.58506 206.989 7.69614C206.736 7.80721 206.621 8.10221 206.732 8.35503L208.341 12.0172L204.679 13.6261C204.426 13.7372 204.311 14.0322 204.422 14.285C204.533 14.5378 204.828 14.6527 205.081 14.5417L209.201 12.7316ZM1.20016 31.4582C21.281 22.686 55.7804 11.2168 93.9755 5.33911C132.179 -0.539818 174.018 -0.815432 208.819 12.7397L209.181 11.8079C174.145 -1.83921 132.103 -1.5399 93.8234 4.35075C55.5364 10.2426 20.9539 21.7377 0.799843 30.5418L1.20016 31.4582Z" />
              </svg>

            </div>

            <div>
              <div>
                <div className="circle2">
                  <h1>2</h1>
                </div>
                <div className="circle circle-two-desc">
                  <h3>Designing</h3>
                  <h4>Designed according to client's requirements</h4>
                </div>
              </div>
              <svg className="vector2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 209 39" fill="none">
                <path strokeWidth="1" opacity="1" fill="white" d="M208.44 23.7654C208.571 23.5226 208.481 23.2191 208.238 23.0875L204.282 20.9434C204.039 20.8118 203.736 20.902 203.604 21.1447C203.472 21.3875 203.563 21.691 203.805 21.8226L207.322 23.7285L205.416 27.2452C205.285 27.488 205.375 27.7915 205.618 27.9231C205.86 28.0546 206.164 27.9645 206.295 27.7217L208.44 23.7654ZM0.636358 1.34317C21.4381 23.3859 91.9493 58.5229 208.142 24.0064L207.858 23.0478C91.9355 57.4839 21.8472 22.3624 1.36364 0.656831L0.636358 1.34317Z" />
              </svg>
            </div>
            <div>
              <div>
                <div className="circle3">
                  <h1>3</h1>
                </div>
                <div className="circle">
                  <h3>Development</h3>
                  <h4>Developed by skilled team developers</h4>
                </div>
              </div>
              <svg className="vector3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 32" fill="none">
                <path strokeWidth="1" opacity="1" fill="white" d="M209.201 12.7316C209.454 12.6205 209.569 12.3255 209.458 12.0727L207.648 7.95279C207.537 7.69997 207.242 7.58506 206.989 7.69614C206.736 7.80721 206.621 8.10221 206.732 8.35503L208.341 12.0172L204.679 13.6261C204.426 13.7372 204.311 14.0322 204.422 14.285C204.533 14.5378 204.828 14.6527 205.081 14.5417L209.201 12.7316ZM1.20016 31.4582C21.281 22.686 55.7804 11.2168 93.9755 5.33911C132.179 -0.539818 174.018 -0.815432 208.819 12.7397L209.181 11.8079C174.145 -1.83921 132.103 -1.5399 93.8234 4.35075C55.5364 10.2426 20.9539 21.7377 0.799843 30.5418L1.20016 31.4582Z" />
              </svg>
            </div>
            <div>
              <div>
                <div className="circle4">
                  <h1>4</h1>
                </div>
                <div className="circle circle-desc">
                  <h3>Live Testing</h3>
                  <h4>After completing the work, live test is done</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- <section> --> */}

      <AnimatedDivs />

      {/* ..........Testimonials.................. */}

      <TestimonialSlider />

      {/* ..........Projects......... */}

      <Projects />

      {/* ..........Image animation......... */}

      <div id="Projects" className="imageAnimation">
        {/* <div
          className="export"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
        >
          <h1
            className="font-face-sa animat"
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-text="Export"
          >
            Export
          </h1>
          <h3>Website</h3>
        </div> */}
        <div className="imgAni">
          <img
            className="noImg1"
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-delay="800"
            src="./images/Screenshot (89).png"
          />
          <img
            className="noImg2"
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-delay="900"
            src="./images/Screenshot (88).png"
          />
          <img
            className="noImg3"
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-delay="1000"
            src="./images/Screenshot (91).png"
          />
          <img
            className="noImg4"
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-delay="1100"
            src="./images/Screenshot (90).png"
          />
        </div>
      </div>

      {/* slider     ........................ */}

      <section className="backgroundanimation">
        {/* <section className="marqi">
          <Marquee direction="up" className="marqee">
            <div className="boxrow">
              <div className="boxcol">
                <div style={{ background: 'transparent', opacity: '0.3', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img style={{ width: '300px', height: '300px' }} src="./images/Screenshot (81).png" /></div>
              </div>
            </div>
          </Marquee>
          <Marquee direction="down" className="marqee">
            <div className="boxrow">
              <div className="boxcol">
                <div style={{ background: 'transparent', opacity: '0.3', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img style={{ width: '300px', height: '300px' }} src="./images/Screenshot (82).png" /></div>
              </div>
            </div>
          </Marquee>
          <Marquee direction="up" className="marqee">
            <div className="boxrow">
              <div className="boxcol">
                <div style={{ background: 'transparent', opacity: '0.3', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img style={{ width: '300px', height: '300px' }} src="./images/Screenshot (83).png" /></div>
              </div>
            </div>
          </Marquee>
          <Marquee direction="down" className="marqee">
            <div className="boxrow">
              <div className="boxcol">
                <div style={{ background: 'transparent', opacity: '0.3', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img style={{ width: '300px', height: '300px' }} src="./images/Screenshot (84).png" /></div>
              </div>
            </div>
          </Marquee>
        </section> */}
        <section className="founder">
          <div>
            <div className="founderheading">
              {/* <h1>Meet our Founder</h1> */}
              <h1>Meet Us</h1>
            </div>
            <div className="founderimage">
              <div>
                <img className="imagemohit" src="./images/yuvraj.jpg" />
              </div>
              <div>
                {/* <h1>Mohit Pareek</h1>
                <h2>Founder</h2> */}
                <h1>Yuvraj Singh</h1>
                <h2>Co-founder & CEO</h2>

                <p>
                'As Yuvraj Singh, a Creative Developer and the Co-founder & CEO of Starting Core Agency, I bring a wealth of expertise in software development, AI development, UI/UX design, and branding. My passion lies in utilizing this experience to transform your online presence and drive long-term growth for your business. Starting Core has been my focus for the past two years, a venture I began during my college days alongside my colleague, Mohit Pareek.
                </p>
                <p>
                At Starting Core Agency, we are dedicated to fostering innovation, delivering educational insights, and driving positive transformation. Our mission is to empower businesses with cutting-edge solutions that ensure sustained success in the digital landscape.
                </p>
                <p>
                I welcome the opportunity to connect with you—whether it’s to explore new ideas, discuss the latest trends, or simply share thoughts. Let’s create something exceptional together!'
                </p>
                <div>
                  <a
                    href="https://www.instagram.com/yuvraj5073singh/"
                    title="Instagram" target="_blank"
                  >
                    <img src="./images/InstagramLogo.png" alt="Instagram" />
                  </a>
                  <a href="mailto:info@startingcore.com" title="Email" target="_blank">
                    <img
                      src="./images/email.png"
                      alt="email"
                      style={{ width: "17px" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/yuvraj8/"
                    title="Linkedin" target="_blank"
                  >
                    <img src="./images/LinkedinLogo.png" alt="Linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ........section............ */}

        <div id="foot" className="foots">


          <footer>
            <div>
              <div className="col-1">
                <div className="circles">
                  <div className="logo-f">
                    <img src="./images/Img1.png" alt="" />
                    <h3 className="SC">Starting core</h3>
                  </div>
                  <div>
                    <p>
                      At STARTING CORE, we understand that influencers and creators
                      are the driving force behind today's dynamic digital
                      landscape. Whether you're a seasoned content creator or just
                      stepping into the realm of influence, we're here to propel
                      your journey to new heights. ”{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-2">
                <div>
                  <div className="col">
                    <h4>Services</h4>
                    <a href="#">Web Development</a>
                    <a href="#">App Development</a>
                    <a href="#">UI/UX Designer</a>
                    <a href="#">Graphic Designer</a>
                    <a href="#">SEO</a>
                  </div>
                  <div className="col">
                    <h4>Help</h4>
                    {/* <!-- <a href="#">Help</a> --> */}
                    <a href="#">Customer Support</a>
                    <a href="#">Terms & Conditions</a>
                    <Link to="/Policy">Privacy Policy</Link>
                  </div>
                  <div className="col">
                    <h4>Company</h4>
                    <a href="#">Home</a>
                    <a href="#">Why us?</a>
                    <a href="#">Projects</a>
                    <a href="#">Working Style</a>
                    <a href="#">Contact Us</a>
                  </div>
                </div>
                <div>
                  <div>
                    <hr />
                    <p>Copyright 2022 @startingcore All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </>
  );
}

export default Landing;
